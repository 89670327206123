<template>
  <b-row>
    <b-col sm="12">
      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col sm="5">
            <h4 id="traffic" class="card-title mb-0">Parámetros</h4>
            <div class="small text-muted">Configuración de los parametros que condiciona el funcionamiento general del software</div>
          </b-col>
        </b-row>
      </b-card>

      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col>

            <b-table class="mb-0"
              responsive="sm"
              head-variant="light"
              :hover="true"
              :small="true"
              :fixed="false"
              :items="table.items"
              :fields="table.fields"
              v-if="table.items.length">

              <template v-slot:cell(value)="data">
                <b-badge variant="success" class="badge-module-permissions-custom" v-if="data.item.value">Activado</b-badge>
                <b-badge variant="danger" class="badge-module-permissions-custom" v-else>Inactivo</b-badge>
              </template>

              <template v-slot:cell(f_action)="data">
                <b-dropdown right text="Acción" size="sm" variant="outline-dark" class="pull-right">
                  <b-dropdown-item @click="configurateConfirm(data.item)" v-if="!data.item.value">
                    <i class="fa fa-check-square-o" style="color:green"></i> Activar
                  </b-dropdown-item>
                  <b-dropdown-item @click="configurateConfirm(data.item)" v-if="data.item.value">
                    <i class="fa fa-square-o" style="color:red"></i> Desactivar
                  </b-dropdown-item>
                </b-dropdown>
              </template>
            </b-table>
            <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>

          </b-col>
        </b-row>
      </b-card>
    </b-col>
  </b-row>
</template>
<script>
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import Session from '@/handler/session'
  import Profiles from '@/config/profiles'

  export default {
    data: () => {
      return {
        table : {
          items: [],
          fields: [
            {key: 'id', label: 'ID'},
            {key: 'description', label: 'Descripción'},
            {key: 'value', label: 'Activado'},
            {key: 'f_action', label: ''},
          ],
        },
        arr: {
          parametrs: [],
        }
      }
    },
    mounted() {
      this.cargar()
    },
    methods: {
      cargar () {
        let loader = this.$loading.show();
        var result = serviceAPI.obtener()

        result.then((response) => {
          var data = response.data
          this.table.items = data
          loader.hide()
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error))
        });
      },
      configurateConfirm(item) {
        this.$bvModal.msgBoxConfirm('¿Desea cambiar el estado de este registro ID >> ' + item.id + '?', {
          title: 'Borrar Usuario',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'dark',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: "dark",
          headerTextVariant: "white",
          hideHeaderClose: false,
          centered: false
        })
        .then(value => {
          if (value) {
            this.configurate(item)
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })
      },
      configurate(item) {
        let loader = this.$loading.show();

        var data ={ id: item.id, value: !item.value}
        var result = serviceAPI.configurar(data);

        result.then((response) => {
          var result1 = serviceAPI.obtenerParametros()

          result1.then((response1) => {
            var data = response1.data

            Session.setSessionParameters(data);
            loader.hide()
            this.cargar()
            this.$awn.success("Parametro configurado con éxito");
          })
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error));
        })
      },
    }
  }
</script>
<style>
  .badge-module-permissions-custom {
    font-size: 14px;
  }
</style>

